.itembadge {
    right: -50px !important;
    margin-top: 8px;
}

.viewInvoiceButton {
    margin-right: 10px !important;
    margin-top: 5px !important;
    padding: 6px !important;
    font-size: 11px !important;
}

.viewInvoiceForm {
    margin-right: 10px !important;
    margin-top: 5px !important;
    padding: 6px !important;
    font-size: 13px !important;
}
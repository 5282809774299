.pricing-wrapper {
    text-align: center;
    max-width: 1200px;
    padding: 10px;
    margin: 5px;
}
.pricing-title {
    font-family: RamaGothic;
    font-size: 45px;
    color: #fab527;
    line-height: 40px;
    margin-top: 5px;
}
.price {
    font-size: 48px;
    font-weight: bold;
    font-family: Gotham-Bold;
    width: 100%;
}
.pricing-from {
    font-size: 25px;
    font-weight: lighter;
    margin-bottom: -14px;
}
.pricing-icon {
    height: 120px;
}
.pricing-footer {
    font-size: 25px;
    font-weight: lighter;
    margin-top: -15px;
}
.add-card{
    padding: 10px;
}
.button{
    color: #000000;
    background-color: #ff0c9c;
}